import { onNextRepaint } from '~/shared/utils/animation';

export const DrbDialog = {
  bindListeners() {
    /*
      Open a remote dialog via the `data-dialog-open-remote` attribute

      ```
        <a href="/remote-url" data-dialog-open-remote="dialog-id">Open Dialog</a>
        or <button data-remote-url="/remote-url" data-dialog-open-remote="dialog-id">Open Dialog</button>
      ```
    */
    document.addEventListener('click', (e) => {
      const remoteDialogTrigger = e.target.closest('[data-dialog-open-remote]');
      if (!remoteDialogTrigger) return;

      e.preventDefault();

      const dialogId = remoteDialogTrigger.getAttribute('data-dialog-open-remote');
      const url = remoteDialogTrigger.getAttribute('href') || remoteDialogTrigger.getAttribute('data-remote-url');
      const shouldReload = !remoteDialogTrigger.hasAttribute('data-remote-disable-reload');
      const preventLightDismiss = remoteDialogTrigger.hasAttribute('data-dialog-prevent-light-dismiss');
      if (!url || !dialogId) return;

      this.openRemote(url, dialogId, shouldReload, preventLightDismiss);
    });
  },

  /*
    Open a remote dialog with the given url and dialog id (via helper method).

    ```
    import { DrbDialog } from '~/web-components/drb-dialog/drb-dialog-helpers';
    DrbDialog.openRemote('/remote-url', 'dialog-id')
    ```
  */
  openRemote(url, dialogId, shouldReload = true, preventLightDismiss = false) {
    let dialog = document.querySelector(`drb-dialog#${dialogId}`);

    // if dialog with matching id doesn't exist, create it
    if (!dialog) {
      dialog = document.createElement('drb-dialog');
      dialog.id = dialogId;
      document.body.appendChild(dialog);
    }

    // set the remote attributes and open the dialog
    dialog.remoteUrl = url;
    dialog.remoteShouldReload = shouldReload;
    dialog.preventLightDismiss = preventLightDismiss;

    // wait for the repaint so the dialog has mounted to the DOM
    onNextRepaint(() => {
      dialog.open();
    });
  },
};
